












































import axios from "axios";
import marked from "marked";
import Vue from "vue";
export default Vue.extend({
  data: () => ({
    serveraddr: Vue.prototype.serverAddr + "/",
    rules: [(value: any) => !!value || "Required"],
    name: "",
    date: "",
    group: [] as any,
    url: {} as any,
    linkname: "",
    linkurl: "",
    type: "",
    types: ["论文", "竞赛"],
    collection: "achievement",

    groupid2name: {
      1: "语音前端",
      2: "语音识别",
      3: "语音合成",
      4: "声纹识别",
      5: "多模态交互",
      9: "其他",
      10: "口语内容",
    },
    groupname2id: {
      语音前端: 1,
      语音识别: 2,
      语音合成: 3,
      声纹识别: 4,
      多模态交互: 5,
      其他: 9,
      口语内容: 10,
    },
    groupname2index: {
      语音前端: 0,
      语音识别: 1,
      语音合成: 2,
      声纹识别: 3,
      多模态交互: 4,
      其他: 5,
      口语内容: 6,
    },
    groups: [
      { name: "语音前端", selected: false },
      { name: "语音识别", selected: false },
      { name: "语音合成", selected: false },
      { name: "声纹识别", selected: false },
      { name: "多模态交互", selected: false },
      { name: "其他", selected: false },
      { name: "口语内容", selected: false },
    ],
    /*
    groups: {
      "语音前端": false,
      "语音识别": false,
      "语音合成": false,
      "声纹识别": false,
      "多模态交互": false,
      "其他": false,
      "口语内容": false,
    }
    */
  }),
  mounted: function () {
    console.log(this.$route.params.id);
    const id = this.$route.params.id;
    if (id != "!") {
      console.log("getting " + id);
      let paper = axios
        .post(Vue.prototype.serverAddr + "/api/get-item-by-id", {
          collection: this.collection,
          _id: id,
        })
        .then((response) => {
          let data: [] = response.data as any;
          if (data.length > 0) {
            const item = (data as any)[0];
            this.name = item.name;
            this.date = item.date != undefined ? item.date : "";
            this.group = item.group;
            this.type = this.types[item.type];
            for (var i = 0; i < this.group.length; i++) {
              (this.groups as any)[
                (this.groupname2index as any)[
                  (this.groupid2name as any)[(this.group as any)[i]]
                ]
              ].selected = true;
            }
            this.url = item.url;
          }
        });
    }
  },
  watch: {},
  methods: {
    deleteUrl: function (name: any) {
      delete this.url[name];
      this.$forceUpdate();
    },
    appendLink: function () {
      (this.url as any)[this.linkname] = this.linkurl;
      this.$forceUpdate();
    },
    submit: function () {
      let method = "";
      if (this.$route.params.id == "!") {
        method = "post";
      } else {
        method = "update";
      }
      console.log("post in paper");
      this.group = [] as any;
      for (var i = 0; i < this.groups.length; i++) {
        if (this.groups[i].selected == true) {
          this.group.unshift(
            Number((this.groupname2id as any)[this.groups[i].name])
          );
        }
      }
      axios
        .post(Vue.prototype.serverAddr + "/api/" + method + "-item", {
          _id: this.$route.params.id,
          collection: this.collection,
          name: this.name,
          type: this.types.indexOf(this.type),
          group: this.group,
          url: this.url,
          date: this.date,
        })
        .then((response) => {
          this.$router.back();
        });
    },
    cancel: function () {
      this.$router.back();
    },
  },
});
